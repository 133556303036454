import { EEarlyRepaymentStatus } from '@dltru/dfa-models'
import { createSelector } from '@reduxjs/toolkit'
import IAppState from '@store/states'

const selectApplications = (state: IAppState) => state.earlyRepayment.items
const selectCurrentApplication = (state: IAppState) => state.earlyRepayment.currentApplication
const selectCurrentFee = (state: IAppState) => state.earlyRepayment.fee
const selectReedemDate = (state: IAppState) => state.earlyRepayment.redeemDate

const selectFee = createSelector(selectCurrentApplication, selectCurrentFee, (application, fee) => {
    if (application?.request.status === EEarlyRepaymentStatus.EXECUTED) {
        return application.request.fee_amount
    }

    return fee
})

export const earlyRepaymentSelector = {
    selectApplications,
    selectCurrentApplication,
    selectFee,
    selectReedemDate,
}
