import { documentsEnumInfo } from '@dltru/dfa-models'
import { downLoadFileBySrc, openMessage } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { profileSelector } from '@store/profiles/details/selectors'

import api from '@services/api'

import { documentsSlice } from './index'

function* downloadDocument({
    payload,
}: ReturnType<typeof documentsSlice.actions.downloadDocument>) {
    try {
        yield* put(
            documentsSlice.actions.setLoading(`${payload.document_name}${payload.params?.user_id}`),
        )

        const { data, error } = yield* call(api.lib.downloadDocuments, payload)
        if (error || data?.error) {
            throw error ?? data?.error
        }
        if (data?.item?.file) {
            const documentInfo = documentsEnumInfo[payload.document_name]
            const userName = yield* select(profileSelector.userFullName)
            const fileSrc = `data:${documentInfo.contentType};base64,${data.item.file}`
            const fileName = `${documentInfo.getTitle({
                userId: payload.params?.user_id,
                userName: userName as string,
                ticker: payload.params?.ticker,
            })}${documentInfo.extension}`

            downLoadFileBySrc(fileSrc, fileName)
        }
    } catch (error) {
        openMessage({ type: 'error', message: 'Не удалось скачать документ' })
    } finally {
        yield* put(
            documentsSlice.actions.unSetLoading(
                `${payload.document_name}${payload.params?.user_id}`,
            ),
        )
    }
}

export function* watchDocuments(): Generator<StrictEffect> {
    yield* takeLatest(documentsSlice.actions.downloadDocument.type, downloadDocument)
}
