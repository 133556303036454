import { PriceSource, dfaBackToFront } from '@dltru/dfa-models'
import { closeMessage, openMessage, openNotification } from '@dltru/dfa-ui'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { getExternalRateHelper, signCustomTransaction, signData } from '@store/helper'

import api from '@services/api'

import { earlyRepaymentActions } from './index'
import { clearDataTransactionDetails, setIsLoadingTransactionDetails, setItemsTransactionDetails } from '@store/sign'
import { transactionDetailsSelectors } from '@store/sign/selectors'

function* createApplicationPrefly({
    payload,
}: ReturnType<typeof earlyRepaymentActions.createApplication>) {
    try {
        openMessage({
          type: 'loading',
          key: 'prefly',
          message: 'Формирование транзакции',
        });
      
        yield * put(setIsLoadingTransactionDetails(true));
        const { data, error } = yield* call(api.lib.postEarlyRepaymentApplicationService, payload)

        if (error || data?.error) {
            throw error ?? data?.error
        }
        yield * put(setItemsTransactionDetails(data));
        closeMessage('prefly');
        yield * put(setIsLoadingTransactionDetails(false));
      } catch (error) {
        yield * put(setIsLoadingTransactionDetails(false));
        closeMessage('prefly');
        openMessage({
          type: 'error',
          message: 'Ошибка формирования транзакции',
        });
      }
}

function* createApplication({
    payload,
}: ReturnType<typeof earlyRepaymentActions.createApplication>) {
    try {
        const data = yield* select(transactionDetailsSelectors.selectItems)

        if (data?.hash) {
            yield* signData(data?.status, payload, { item: data }, api.lib.putTransaction)
        }
        yield* put(clearDataTransactionDetails())
        openNotification({
            type: 'success',
            message: 'заявка успешно создана',
        })
    } catch (error) {
        yield* put(clearDataTransactionDetails())
        openNotification({
            type: 'error',
            message: 'ошибка при создании заявки',
        })
    }
}

function* getApplications({ payload }: ReturnType<typeof earlyRepaymentActions.getApplications>) {
    try {
        const { data, error } = yield* call(api.lib.getEarlyRepaymentApplicationsService, payload)

        if (error || data?.error) {
            throw error ?? data?.error
        }

        yield* put(earlyRepaymentActions.setEarlyRepaymentItems(data))
    } catch (error) {
        openNotification({
            type: 'error',
            message: 'Ошибка получения заявок',
        })
    }
}

function* confirmByIssuerPrefly({ payload }: ReturnType<typeof earlyRepaymentActions.confirmByIssuer>) {
    try {
        openMessage({
          type: 'loading',
          key: 'prefly',
          message: 'Формирование транзакции',
        });
      
        yield * put(setIsLoadingTransactionDetails(true));
      
        const { data, error } = yield* call(
            api.lib.postEarlyRepaymentConfirmByIssuerService,
            payload,
        )

        if (error || data?.error) {
            throw error ?? data?.error
        }

        yield * put(setItemsTransactionDetails(data));
        closeMessage('prefly');
        yield * put(setIsLoadingTransactionDetails(false));
      } catch (error) {
        yield * put(setIsLoadingTransactionDetails(false));
        closeMessage('prefly');
        openMessage({
          type: 'error',
          message: 'Ошибка формирования транзакции',
        });
      }
}

function* confirmByIssuer({ payload }: ReturnType<typeof earlyRepaymentActions.confirmByIssuer>) {
    try {
        const data = yield* select(transactionDetailsSelectors.selectItems)

        if (data?.hash) {
            const dataForSign = {
                request_uuid: payload.request_uuid,
                repayment_fee: payload.fee,
                transaction: data,
            }
            yield* signCustomTransaction(
                payload.skid,
                dataForSign,
                api.lib.putEarlyRepaymentConfirmByIssuerService,
            )
            yield* put(clearDataTransactionDetails())

        }

        openNotification({
            type: 'success',
            message: 'заявка успешно подверждена',
        })

        yield* put(earlyRepaymentActions.getApplicationByUuid(payload.request_uuid))
    } catch (error) {
        yield* put(clearDataTransactionDetails())

        openNotification({
            type: 'error',
            message: 'Ошибка подтверждения',
        })
    }
}

function* getFee({ payload }: ReturnType<typeof earlyRepaymentActions.getFee>) {
    try {
        const { data, error } = yield* call(api.lib.getEarlyRepaymentFeeService, payload)

        if (error || data?.error) {
            throw error ?? data?.error
        }

        yield* put(earlyRepaymentActions.setEarlyRepaymentFee(data.fee))
    } catch (error) {
        openNotification({
            type: 'error',
            message: 'Ошибка получения комиссии',
        })
    }
}

function* confirmByInvestorPrefly({
    payload,
}: ReturnType<typeof earlyRepaymentActions.confirmByInvestor>) {
    try {
        openMessage({
          type: 'loading',
          key: 'prefly',
          message: 'Формирование транзакции',
        });
      
        yield * put(setIsLoadingTransactionDetails(true));

        const { data, error } = yield* call(
            api.lib.postEarlyRepaymentConfirmByInvestorService,
            payload,
        )

        if (error || data?.error) {
            throw error ?? data?.error
        }
      
        yield * put(setItemsTransactionDetails(data));
        closeMessage('prefly');
        yield * put(setIsLoadingTransactionDetails(false));
      } catch (error) {
        yield * put(setIsLoadingTransactionDetails(false));
        closeMessage('prefly');
        openMessage({
          type: 'error',
          message: 'Ошибка формирования транзакции',
        });
      }
}

function* confirmByInvestor({
    payload,
}: ReturnType<typeof earlyRepaymentActions.confirmByInvestor>) {
    try {
        const data = yield* select(transactionDetailsSelectors.selectItems)

        if (data?.hash) {
            yield* signData(data?.status, payload.skid, { item: data }, api.lib.putTransaction)
        }
        yield* put(clearDataTransactionDetails())
        yield* put(earlyRepaymentActions.getApplicationByUuid(payload.request_uuid))
    } catch (error) {
        yield* put(clearDataTransactionDetails())
        openNotification({
            type: 'error',
            message: 'Ошибка погашения',
        })
    }
}

function* getApplicationByUuid({
    payload,
}: ReturnType<typeof earlyRepaymentActions.getApplicationByUuid>) {
    try {
        const { data, error } = yield* call(api.lib.getEarlyRepaymentByUuidService, payload)

        if (error || data?.error || !data?.asset) {
            throw error ?? data?.error
        }

        if (data.request.meta) {
            data.request.meta = JSON.parse(data.request.meta)
        }

        if (data?.asset) {
            if (
                data?.asset?.price_source_type === PriceSource.dynamic &&
                data?.asset?.price_source
            ) {
                const { item } = yield* call(getExternalRateHelper, data.asset.price_source)
                data.asset.price_external = item.rate / 100
            }

            const frontTypedDfa = dfaBackToFront(data?.asset)
            yield* put(
                earlyRepaymentActions.setEarlyRepaymentCurrentApplication({
                    ...data,
                    asset: frontTypedDfa,
                }),
            )
        }
    } catch (error) {
        openNotification({
            type: 'error',
            message: 'Ошибка получения заявки',
        })
    }
}

export function* watchEarlyRepayment(): Generator<StrictEffect> {
    yield* takeLatest(earlyRepaymentActions.createApplication.type, createApplication)
    yield* takeLatest(earlyRepaymentActions.createApplicationPrefly.type, createApplicationPrefly)
    yield* takeLatest(earlyRepaymentActions.getApplications.type, getApplications)
    yield* takeLatest(earlyRepaymentActions.confirmByIssuer.type, confirmByIssuer)
    yield* takeLatest(earlyRepaymentActions.confirmByIssuerPrefly.type, confirmByIssuerPrefly)
    yield* takeLatest(earlyRepaymentActions.getFee.type, getFee)
    yield* takeLatest(earlyRepaymentActions.confirmByInvestor.type, confirmByInvestor)
    yield* takeLatest(earlyRepaymentActions.confirmByInvestorPrefly.type, confirmByInvestorPrefly)
    yield* takeLatest(earlyRepaymentActions.getApplicationByUuid.type, getApplicationByUuid)
}
