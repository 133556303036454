import {
    IEarlyRepaymentFeeResponse,
    IEarlyRepaymentPostReques,
    IEarlyRepaymentRquest,
} from '@dltru/dfa-models'
import { createAction, createSlice } from '@reduxjs/toolkit'


import { initialState } from './resources'
import { IApplicationsTable } from './types'

const earlyRepaymentSlice = createSlice({
    name: 'earlyRepayment',
    initialState,
    reducers: {
        setEarlyRepaymentItems: (state, action) => {
            state.items = action.payload
        },
        dropEarlyRepaymentItems: (state) => {
            state.items = {}
        },
        setEarlyRepaymentCurrentApplication: (state, action) => {
            state.currentApplication = action.payload
        },
        dropEarlyRepaymentCurrentApplication: (state) => {
            state.currentApplication = null
        },
        setEarlyRepaymentFee: (state, action) => {
            state.fee = action.payload
        },
        dropEarlyRepaymentFee: (state) => {
            state.fee = null
        },
        dropEarlyRepaymentCurrentAndFee: (state) => {
            state.currentApplication = null
            state.fee = null
        },
        setEarlyRepaymentConfirmByIssuer: (state, action) => {
            state.confirmByIssuer = action.payload
        },
        dropEarlyRepaymentConfirmByIssuer: (state) => {
            state.confirmByIssuer = null
        },
    },
})

export const createApplication = createAction<string>(
    'earlyRepayment/createApplication',
)

export const createApplicationPrefly = createAction<IEarlyRepaymentPostReques>(
    'earlyRepayment/createApplicationPrefly',
)
export const getApplications = createAction<IApplicationsTable | undefined>(
    'earlyRepayment/getApplications',
)
export const confirmByIssuer = createAction<IEarlyRepaymentRquest>('earlyRepayment/confirmByIssuer')
export const confirmByIssuerPrefly = createAction<IEarlyRepaymentRquest>('earlyRepayment/confirmByIssuerPrefly')

export const getFee = createAction<IEarlyRepaymentFeeResponse>('earlyRepayment/getFee')
export const confirmByInvestor = createAction<IEarlyRepaymentRquest>(
    'earlyRepayment/confirmByInvestor',
)
export const confirmByInvestorPrefly = createAction<IEarlyRepaymentRquest>(
    'earlyRepayment/confirmByInvestorPrefly',
)
export const getApplicationByUuid = createAction<string>('earlyRepayment/getApplicationByUuid')
export const getEarlyRepaymentEntryRedeemDate = createAction<string>(
    'earlyRepayment/getEarlyRepaymentEntryRedeemDate',
)

export const earlyRepaymentActions = {
    ...earlyRepaymentSlice.actions,
    createApplication,
    createApplicationPrefly,
    getApplications,
    confirmByIssuer,
    confirmByIssuerPrefly,
    confirmByInvestorPrefly,
    getFee,
    confirmByInvestor,
    getApplicationByUuid,
    getEarlyRepaymentEntryRedeemDate,
}

export default earlyRepaymentSlice.reducer
